<div *ngIf="label"
     class="label"
     (click)="focus()">{{label}}</div>
<p-dropdown #dropdown
            autoWidth="false"
            [options]="options"
            [optionLabel]="optionLabel"
            [optionValue]="optionKey"
            [placeholder]="placeholder"
            [group]="group"
            [filter]="filter"
            [filterBy]="filterBy"
            [filterPlaceholder]="filterPlaceholder"
            [tabindex]="tabindex"
            [disabled]="disabled"
            [readonly]="calculatedReadOnly"
            [required]="required"
            [style]="inlineStyle"
            [ngStyle]="inlineStyle"
            [styleClass]="styleClass"
            [appendTo]="appendTo"
            (onShow)="onShow($event)"
            (onHide)="onHide($event)"
            (onFocus)="onFocus()"
            (onChange)="onUpdate()"
            [(ngModel)]="value">
</p-dropdown>
